import Vapi from "@vapi-ai/web";
import toast, { Toaster } from "react-hot-toast";

export default function RolePlay() {
  const vapi = new Vapi("221b004a-0b22-41b8-aca7-09818aa45432");
  const backendUrl = "https://app.mavlon.co/";

  const startCall = async () => {
    console.log("start-call");
    document.getElementById("makeCallBtn").innerHTML =
      '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...';
    document.getElementById("makeCallBtn").disabled = true;
    const assistant = {
      firstMessage: "Olá, como você está?",
      model: {
        provider: "openai",
        model: "gpt-3.5-turbo-16k",
        messages: [
          {
            role: "system",
            content:
              "You are a VP of sales of a multinational large pharma company, you are looking for a sales training platform to train your sales team. You are in a demo session where sales agent will be demoing you their product and qualifying you as their ideal customer. Cross questions the sales agent and be little enthusiastic by asking the features and benefits of the products. Be curious and  raise objections wherever possible so that the real sales skill of the sales agent can be tested under pressure. Ensure to keep the conversation going by asking follow-up questions.",
          },
        ],
      },
      voice: "jennifer-playht",
      recordingEnabled: false,
      interruptionsEnabled: false,
    };

    const assistantOverrides = {
      transcriber: {
        provider: "deepgram",
        model: "nova-2",
        language: "en-US",
        model: "general",
        language: "pt-BR",
      },
      voice: {
        provider: "azure",
        voiceId: "pt-BR-FranciscaNeural",
      },
    };
    const call = await vapi.start(assistant, assistantOverrides);
    vapi.on("speech-start", () => {
      console.log("Speech has started");
    });

    vapi.on("speech-end", () => {
      console.log("Speech has ended");
    });

    vapi.on("call-start", () => {
      console.log("Call has started");
    });

    vapi.on("call-end", () => {
      console.log("Call has stopped");
    });

    vapi.on("volume-level", (volume) => {
      console.log(`Assistant volume level: ${volume}`);
    });

    vapi.on("message", (message) => {
      console.log("Message received:", message);
    });

    vapi.on("error", (e) => {
      console.error("Error:", e);
    });
    // const token = localStorage.getItem("token");
    // const response = await fetch(`${backendUrl}tab_bot/call_status/`, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${token}`,
    //   },
    //   body: JSON.stringify({ call_id: call.id }),
    // });
    // const data = await response.json();
    // if(data.status ==  "in_progress"){
    //   vapi.stop();
    //   document.getElementById("makeCallBtn").disabled = false;
    //   document.getElementById("makeCallBtn").classList.remove("d-none");
    //   document.getElementById("endCallBtn").classList.add("d-none");
    //   document.getElementById("makeCallBtn").innerHTML = "Make a call";
    //   toast.error("You already have one call in progress." , {
    //     duration: 2000,
    //     position: "top-center",
    //   });
    //   return;
    // }

    document.getElementById("makeCallBtn").disabled = false;
    document.getElementById("makeCallBtn").classList.add("d-none");
    document.getElementById("endCallBtn").classList.remove("d-none");
    document.getElementById("makeCallBtn").innerHTML = "Make a call";
  };

  const endCall = () => {
    console.log("end-call");
    vapi.stop();
    document.getElementById("makeCallBtn").classList.remove("d-none");
    document.getElementById("endCallBtn").classList.add("d-none");
  };

  return (
    <>
      <div class="app-main flex-column flex-row-fluid" id="kt_app_main">
        <div class="d-flex flex-column flex-column-fluid">
          <div id="kt_app_toolbar" class="app-toolbar pt-7 pt-lg-10">
            <div
              id="kt_app_toolbar_container"
              class="app-container container-fluid d-flex align-items-stretch"
            >
              <Toaster position="top-center" />
              <div class="d-flex flex-stack flex-row-fluid">
                <div class="d-flex flex-column flex-row-fluid">
                  <div class="page-title d-flex align-items-center gap-1 me-3">
                    <span class="text-gray-900 fw-bolder fs-2x lh-1">
                      Roleplay for AI
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="kt_app_content" class="app-content flex-column-fluid">
            <div
              id="kt_app_content_container"
              class="app-container container-fluid"
            >
              <div class="card">
                <div class="card-body p-10 p-lg-15">
                  <div class="row">
                    <div class="col-xxl-6 mb-5 mb-xxl-0">
                      <div
                        class="card border-0 h-md-100"
                        data-bs-theme="light"
                        style={{
                          background:
                            "linear-gradient(112.14deg, #00d2ff 0%, #3a7bd5 100%)",
                        }}
                      >
                        <div class="card-body">
                          <div class="row align-items-center h-100">
                            <div class="col-7 ps-xl-13">
                              <div class="text-white mb-6 pt-6">
                                <span class="fs-4 fw-semibold me-2 d-block lh-1 pb-2 opacity-75">
                                  Get Set Go!
                                </span>
                                <span class="fs-2qx fw-bold">
                                  Test your sales prowess with Eva
                                </span>
                              </div>

                              <span class="fw-semibold text-white fs-6 mb-8 d-block opacity-75">
                                Eva is a your dummy customer, mock differnet
                                scenarios and test your sales skills
                              </span>

                              <div class="d-flex align-items-center flex-wrap d-grid gap-2 mb-10 mb-xl-20">
                                <div class="d-flex align-items-center me-5 me-xl-13">
                                  <div class="symbol symbol-30px symbol-circle me-3">
                                    <span
                                      class="symbol-label"
                                      style={{ background: "#35c7ff" }}
                                    >
                                      <i class="ki-duotone ki-abstract-41 fs-5 text-white">
                                        <span class="path1"></span>
                                        <span class="path2"></span>
                                      </i>
                                    </span>
                                  </div>

                                  <div class="text-white">
                                    <span class="fw-semibold d-block fs-8 opacity-75">
                                      Hours
                                    </span>
                                    <span class="fw-bold fs-7">Up to 100</span>
                                  </div>
                                </div>

                                <div class="d-flex align-items-center">
                                  <div class="symbol symbol-30px symbol-circle me-3">
                                    <span
                                      class="symbol-label"
                                      style={{ background: "#35c7ff" }}
                                    >
                                      <i class="ki-duotone ki-abstract-26 fs-5 text-white">
                                        <span class="path1"></span>
                                        <span class="path2"></span>
                                      </i>
                                    </span>
                                  </div>

                                  <div class="text-white">
                                    <span class="fw-semibold opacity-75 d-block fs-8">
                                      Skills
                                    </span>
                                    <span class="fw-bold fs-7">
                                      SPIN selling
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div className="d-flex flex-column flex-sm-row d-grid gap-2">
                                <button
                                  id="makeCallBtn"
                                  className="btn btn-success flex-shrink-0 me-lg-2"
                                  onClick={startCall}
                                >
                                  Make a call
                                </button>
                                <button
                                  id="endCallBtn"
                                  className="btn btn-danger flex-shrink-0 me-lg-2 d-none"
                                  onClick={endCall}
                                >
                                  End Call
                                </button>

                                <a
                                  class="btn btn-primary flex-shrink-0"
                                  style={{
                                    background: "rgba(255, 255, 255, 0.2)",
                                  }}
                                >
                                  Edit Persona
                                </a>
                              </div>
                            </div>

                            <div class="col-5 pt-10">
                              <div
                                class="bgi-no-repeat bgi-size-contain bgi-position-x-end h-225px"
                                style={{
                                  backgroundImage:
                                    "url('assets/media/avatars/agent-2.png')",
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-xxl-6">
                      <div
                        class="card border-0 h-md-100"
                        data-bs-theme="light"
                        style={{
                          background:
                            "linear-gradient(112.14deg, #ff6b6b 0%, #ff6b6b 100%)",
                        }}
                      >
                        <div class="card-body">
                          <div class="row align-items-center h-100">
                            <div class="col-7 ps-xl-13">
                              <div class="text-white mb-6 pt-6">
                                <span class="fs-4 fw-semibold me-2 d-block lh-1 pb-2 opacity-75">
                                  Ready to Start?
                                </span>
                                <span class="fs-2qx fw-bold">
                                  Practice Your Skills
                                </span>
                              </div>

                              <span class="fw-semibold text-white fs-6 mb-8 d-block opacity-75">
                                Enhance your abilities through interactive
                                roleplay scenarios
                              </span>

                              <div class="d-flex align-items-center flex-wrap d-grid gap-2 mb-10 mb-xl-20">
                                <div class="d-flex align-items-center me-5 me-xl-13">
                                  <div class="symbol symbol-30px symbol-circle me-3">
                                    <span
                                      class="symbol-label"
                                      style={{
                                        background: "#ff6b6b",
                                      }}
                                    >
                                      <i class="ki-duotone ki-profile-circle fs-5 text-white">
                                        <span class="path1"></span>
                                        <span class="path2"></span>
                                        <span class="path3"></span>
                                      </i>
                                    </span>
                                  </div>

                                  <div class="text-white">
                                    <span class="fw-semibold d-block fs-8 opacity-75">
                                      Scenarios
                                    </span>
                                    <span class="fw-bold fs-7">20+</span>
                                  </div>
                                </div>

                                <div class="d-flex align-items-center">
                                  <div class="symbol symbol-30px symbol-circle me-3">
                                    <span
                                      class="symbol-label"
                                      style={{
                                        background: "#ff6b6b",
                                      }}
                                    >
                                      <i class="ki-duotone ki-rocket fs-5 text-white">
                                        <span class="path1"></span>
                                        <span class="path2"></span>
                                      </i>
                                    </span>
                                  </div>

                                  <div class="text-white">
                                    <span class="fw-semibold opacity-75 d-block fs-8">
                                      Skills
                                    </span>
                                    <span class="fw-bold fs-7">Multiple</span>
                                  </div>
                                </div>
                              </div>

                              <div class="d-flex flex-column flex-sm-row d-grid gap-2">
                                <a
                                  href="#"
                                  class="btn btn-warning flex-shrink-0 me-lg-2"
                                >
                                  Start Practice
                                </a>
                                <a
                                  href="#"
                                  class="btn btn-primary flex-shrink-0"
                                  style={{
                                    background: "rgba(255, 255, 255, 0.2)",
                                  }}
                                >
                                  View Scenarios
                                </a>
                              </div>
                            </div>

                            <div class="col-5 pt-10">
                              <div
                                class="bgi-no-repeat bgi-size-contain bgi-position-x-end h-225px"
                                style={{
                                  background:
                                    "assets/media/avatars/agent-2.png",
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
