import "./App.css";
import RolePlay from "./components/RolePlay";
import CheckAuth from "./components/CheckAuth";

function App() {
  return (
    // <CheckAuth>
      <RolePlay />
    // </CheckAuth> 
  );
}

export default App;
